import React, { useEffect } from "react";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import WhatsAppChat from "../Components/common/WhatsAppChat";
import { Helmet } from "react-helmet";

export const Layout = ({ children,title }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <NavBar />
      <div>{children}</div>
      <Footer />
      <WhatsAppChat
        phoneNumber="9540111207"
        message="Hello, I would like to chat!"
      />
    </>
  );
};
