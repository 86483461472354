import { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import  LoadingScreen  = lazy(() => import("../pages/LoadingScreen").then(module => ({ default: module.Home })));  from "../Components/Looad/LoadingScreen";

// Lazy-loaded components
const Home = lazy(() => import("../pages/home").then(module => ({ default: module.Home })));
const About = lazy(() => import("../pages/About").then(module => ({ default: module.About })));
const PageNotFound = lazy(() => import("../pages/404").then(module => ({ default: module.PageNotFound })));
const Packages = lazy(() => import("../pages/Packages").then(module => ({ default: module.Packages })));
const Blogs = lazy(() => import("../pages/Blogs").then(module => ({ default: module.Blogs })));
const Packagesdetails = lazy(() => import("../pages/Packages-details").then(module => ({ default: module.Packagesdetails })));
const Visa = lazy(() => import("../pages/Visa").then(module => ({ default: module.Visa })));
const Contact = lazy(() => import("../pages/Contact").then(module => ({ default: module.Contact })));
const Blogsdetails = lazy(() => import("../pages/Blogsdetails").then(module => ({ default: module.Blogsdetails })));
const Login = lazy(() => import("../pages/Login").then(module => ({ default: module.Login })));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy").then(module => ({ default: module.PrivacyPolicy })));

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <Suspense fallback={<div></div>}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <Suspense fallback={<div></div>}>
          <PrivacyPolicy />
        </Suspense>
      ),
    },
    {
      path: "/about-us",
      element: (
        <Suspense fallback={<div></div>}>
          <About />
        </Suspense>
      ),
    },
    {
      path: "/packages",
      element: (
        <Suspense fallback={<div></div>}>
          <Packages />
        </Suspense>
      ),
      children: [],
    },
    {
      path: "/package-details",
      element: (
        <Suspense fallback={<div></div>}>
          <Packagesdetails />
        </Suspense>
      ),
    },
    {
      path: "/oman-visa",
      element: (
        <Suspense fallback={<div></div>}>
          <Visa />
        </Suspense>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <Suspense fallback={<div></div>}>
          <Contact />
        </Suspense>
      ),
    },
    {
      path: "/blogs-details/:id",
      element: (
        <Suspense fallback={<div></div>}>
          <Blogsdetails />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense fallback={<div></div>}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<div></div>}>
          <PageNotFound />
        </Suspense>
      ),
    },
  ]);
};

export default Router;
